angular.module('SimonsSteigerbouwPlanning.services.UrlService', [])

.service('UrlService', ['$rootScope', '$http', 'store', function($rootScope, $http, store) {
    var service = this;
    var baseUrl = 'https://planning.simonssteigerbouw.nl/ws/';

    service.loginUrl = baseUrl + 'loginapp';
    service.forgotPasswordUrl = baseUrl + 'forgotpassword';
    
    service.materialUrl = baseUrl + 'material/all';

    service.itemUrl = baseUrl + 'item/all';
    service.addItemUrl = baseUrl + 'item/add';
    service.itemPhotoEP = baseUrl + 'item/image/';
    service.getItemPhotoEP = baseUrl + 'itemimage/';
    service.clientUrl = baseUrl + 'client/all';
    service.addClientUrl = baseUrl + 'client/add';

    service.doPost = function (url, data, onSuccess, onError) {
        console.log('DO URL POST: ' + url);
        $http({
            method: 'post',
            url: url,
            data: data,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + store.get('jwt')
            }
        }).then(function (res) {
            onSuccess(res);
        }, function (res) {
            onError(res);
        });
    };

    service.doGet = function (url, onSuccess, onError) {
        console.log('DO URL GET: ' + url);
        $http({
            method: 'get',
            url: url,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + store.get('jwt')
            }
        }).then(function (res) {
            res.url = url;
            onSuccess(res);
        }, function (res) {
            onError(res);
        });
    };

    service.doPut = function (url, data, onSuccess, onError) {
        console.log('DO URL PUT: ' + url);
        $http({
            method: 'put',
            url: url,
            data: data,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + store.get('jwt')
            }
        }).then(function (res) {
            onSuccess(res);
        }, function (res) {
            onError(res);
        });
    };
}]);
