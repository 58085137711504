angular.module('SimonsSteigerbouwPlanning.services.LoginService', [])

.service('LoginService', ['$rootScope', '$http', 'store', 'jwtHelper', 'ApiService', function($rootScope, $http, store, jwtHelper, ApiService) {
    var api = ApiService;

    var loginService = this;
    var loggedInUser = null;

    this.login = function (user, onSuccess, onError) {
        $http({
            method: 'post',
            url: api.getLoginEP(),
            data: user
        }).then(function (res) {
            store.set('jwt', res.data.id_token);
            loginService.getLoggedInUser();
            onSuccess();
            $rootScope.$broadcast('login');
        }, function (res) {
            onError(res.data);
        });
    };

    this.logout = function () {
        loginService.loggedInUser = {};
        $rootScope.$broadcast('logout');
        store.remove('jwt');
    };

    this.isLoggedIn = function () {
        return typeof store.get('jwt') == 'string';
    };

    this.getLoggedInUser = function () {
        if (!loginService.isLoggedIn()) return null;
        loginService.loggedInUser = jwtHelper.decodeToken(store.get('jwt')).user;
        return loginService.loggedInUser;
    };

    this.forgotPassword = function (email, onSuccess, onError) {
        $http({
            method: 'post',
            url: api.getForgotPasswordEP(),
            data: email
        }).then(onSuccess, onError);
    };
}]);
