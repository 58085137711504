angular.module('SimonsSteigerbouwPlanning.controllers.ClientController', [])

.controller('ClientController', ['$scope', '$rootScope', '$timeout', 'ngDialog', 'NavigationService', 'UserService', '$location', 'LoginService', 'ClientService', function($scope, $rootScope, $timeout, ngDialog, NavigationService, UserService, $location, LoginService, ClientService) {

    /*INIT PAGE SECTION*/
    NavigationService.setTitle("Klanten");
    NavigationService.setPageName("Clientlist");
    $rootScope.$broadcast('refreshUi');

    $scope.isloading = true;
    $scope.loadingtext = '';
    $scope.$on('api-start', function(event, data) {
        $scope.loadingtext = data;
        $scope.isloading = true;
    });
    $scope.$on('api-done', function(event, data) {
        $timeout(function() {
            $scope.loadingtext = '';
            $scope.isloading = false;
        }, 300);
    });
    $rootScope.$on('refresh', function() {
        $scope.refreshClients();
    });

    $scope.items = [];

    $scope.refreshClients = function() {
        ClientService.getClients(function(clients) {
            $scope.clients = clients;
            $rootScope.$broadcast('loading-stop');
        }, function(errors) {
            $scope.errors = errors;
            console.log('Error: ', errors);
            $rootScope.$broadcast('loading-stop');
        });
    };
    $scope.refreshClients();

    $rootScope.$broadcast('client-view');

    /*ORDER COLUMN SECTION*/
    $scope.order = 'lastname';
    $scope.asc = false;
    $scope.setOrder = function (col) {
        if($scope.order == col) {
            $scope.asc = !$scope.asc;
        } else {
            $scope.order = col;
            $scope.asc = true;
        }
    };

    $scope.openClientDetail = function (client, readonly) {
        ClientService.getClientDetails(client.id, function(detailedClient) {
            NavigationService.setTitle(detailedClient.name);
            $rootScope.$broadcast('refreshUi');
            $location.path('/clientdetail/true');
        });
    };
        
    $scope.logout = function() {
        LoginService.logout();
        $location.path('/login');
        $scope.showLogout = false;
        NavigationService.setShowLogout(false);
        $scope.showBack = false;
        NavigationService.setShowBack(false);
    };
}]);
