angular.module('SimonsSteigerbouwPlanning.directives.ResizeInputDirective', [])

.directive('resizeInput', function() {
   return function(scope, element, attr){
      var elInput = element.find('input');
      var elDummy = element.find('span');
      var inputText = elInput.val();
      elDummy.html(inputText);
      elInput.bind("change paste keyup", function(){
        var inputText = elInput.val();
        elDummy.html(inputText);
        elInput.css('width', (elDummy[0].offsetWidth + 15) + 'px');
      });

    }
});
