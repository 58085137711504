angular.module('SimonsSteigerbouwPlanning.services.AppService', [])

.service('AppService', ['$rootScope', '$http', 'ApiService', 'UserService', 'UrlService', 'cfpLoadingBar', function($rootScope, $http, ApiService, UserService, UrlService, cfpLoadingBar) {
    var service = this;
    var dateString = "Vandaag";
    var todayDate = new Date();

    var lastError = null;
    var optionsList = [
        {
            text: "Ja",
            value: 1
        },
        {
            text: "Nee",
            value: 0
        }
    ];

    /*service.hideKeyboard = function () {
        try {
            cordova.plugins.Keyboard.show();
        } catch (err) {
            console.log('cordova not found');
        }
        //$ionicScrollDelegate.resize();
    };*/

    service.getDay = function (current) {
        var self = this;
        dateString = self.getDateString(current);
        var nextDay = self.getNextDay(current);
        if (current.toDateString() == todayDate.toDateString()) {
            dateString = "Vandaag";
            today = true;
        } else if (nextDay.toDateString() == todayDate.toDateString()) {
            dateString = "Gisteren";
            today = false;
        } else {
            today = false;
        }

        return {
            "date": current,
            "dateString": dateString,
            "today": today
        };
    };

    service.prevDay = function (current, today) {
        var self = this;
        var newDate = self.getPrevDay(new Date(current));
        if (today) {
            dateString = "Gisteren";
        } else {
            dateString = self.getDateString(newDate);
        }

        if (newDate.toDateString() == todayDate.toDateString()) {
            today = true;
        } else {
            today = false;
        }
        return {
            "date": newDate,
            "dateString": dateString,
            "today": today
        };
    };

    service.nextDay = function (current) {
        var today = false;
        var self = this;
        var newDate = self.getNextDay(new Date(current));
        var newDatePlusOne = new Date(current);
        newDatePlusOne.setDate(newDatePlusOne.getDate() + 2);
        dateString = self.getDateString(newDate);
        if (newDate.toDateString() == todayDate.toDateString()) {
            today = true;
            dateString = "Vandaag";
        } else if (newDatePlusOne.toDateString() == todayDate.toDateString()) {
            dateString = "Gisteren";
        }
        return {
            "date": newDate,
            "dateString": dateString,
            "today": today
        };
    };

    service.getDaysAfter = function (date, days) {
        var newDate = new Date(date);
        newDate.setDate(date.getDate() + days);
        return newDate;
    };

    service.getFiveDaysBefore = function (date) {
        var newDate = new Date(date);
        newDate.setDate(date.getDate() - 14);
        return newDate;
    };

    service.getNextDay = function (date) {
        var newDate = new Date(date);
        newDate.setDate(date.getDate() + 1);
        return newDate;
    };

    service.getPrevDay = function (date) {
        var newDate = new Date(date);
        newDate.setDate(date.getDate() - 1);
        return newDate;
    };

    service.n = function (value) {
        if (value < 10) {
            return '0' + value;
        } else {
            return value;
        }
    };

    service.getShortDateString = function (date) {
        var doDate = new Date();
        if (typeof date == "string") {
            doDate = new Date(date.substring(0, 4), (parseInt(date.substring(5, 7)) - 1), date.substring(8, 10), date.substring(11, 13), date.substring(14, 16), date.substring(17, 19));
        } else {
            doDate = new Date(date);
        }
        var returnDate = doDate;
        var self = this;
        var key = self.n(returnDate.getDate()) + "-" + self.n(returnDate.getMonth() + 1) + "-'" + returnDate.getFullYear().toString().substring(2, 4);
        return key;
    };

    service.getDateString = function (date) {
        var doDate = new Date();
        if (typeof date == "string") {
            doDate = new Date(date.substring(0, 4), (parseInt(date.substring(5, 7)) - 1), date.substring(8, 10), date.substring(11, 13), date.substring(14, 16), date.substring(17, 19));
        } else {
            doDate = new Date(date);
        }
        var returnDate = doDate;
        var self = this;
        var key = self.n(returnDate.getDate()) + '-' + self.n(returnDate.getMonth() + 1) + '-' + returnDate.getFullYear();
        return key;
    };

    service.checkIOSDate = function (date) {
        var doDate = new Date();
        if (typeof date == "string") {
            doDate = new Date(date.substring(0, 4), (parseInt(date.substring(5, 7)) - 1), date.substring(8, 10), date.substring(11, 13), date.substring(14, 16), date.substring(17, 19));
        } else {
            doDate = new Date(date);
        }
        return doDate;
    };

    service.getTimeString = function (datetime) {
        var self = this;
        var storeDate = new Date(datetime);
        //console.log(storeDate + ', ' + storeDate.getHours() + ':' + storeDate.getMinutes() + ' uur');
        //var storeDate = new Date(datetime.getTime() + ((datetime.getTimezoneOffset() * -1) * 60000));
        //console.log(storeDate);
        return self.n(storeDate.getHours()) + ':' + self.n(storeDate.getMinutes()) + ' uur';
    };

    service.getStoreDateTime = function (date, time) {
        var intakeDate = new Date(date);
        intakeDate.setHours(time.getHours());
        intakeDate.setMinutes(time.getMinutes());
        intakeDate.setSeconds(time.getSeconds());
        return intakeDate;
    };

    service.setTimeForDate = function (date, extraHours) {
        var self = this;
        var newDate = new Date(date);
        //                if (extraHours != null && extraHours != undefined) {
        //                    newDate.setHours(self.n(date.getHours() + extraHours));
        //                } else {
        newDate.setHours(self.n(date.getHours()));
        //}
        newDate.setMinutes(self.n(date.getMinutes()));
        newDate.setSeconds(0, 00);
        if (extraHours != undefined && extraHours != null) {
            //var storeDate = new Date(newDate.getTime() + ((newDate.getTimezoneOffset() * -1) * 60000));
            //console.log(storeDate);
            //                var storeDate = new Date(newDate.getTime() + (newDate.getTimezoneOffset() * 60000));
            //                console.log(storeDate);
            //return storeDate;
            return newDate;
        } else {
            return newDate;
        }
    };

    service.resetTimeForeDate = function (date) {
        var newDate = new Date(date);
        newDate.setHours(0);
        newDate.setMinutes(0);
        newDate.setSeconds(0, 00);
        return newDate;
    };

    service.getOptionsList = function () {
        return optionsList;
    };

    service.showLoadIcon = function () {
        var loadIcon = document.querySelector('.loadingIcon');
        if (loadIcon != null && loadIcon != undefined) {
            loadIcon.classList.remove('hide');
        }
    };

    service.hideLoadIcon = function () {
        var loadIcon = document.querySelector('.loadingIcon');
        if (loadIcon != null && loadIcon != undefined) {
            loadIcon.classList.add('hide');
        }
    };

    service.showContainer = function () {
        var self = this;
        self.showLoadIcon();
        var loadCnt = document.querySelector('#loadContainer');
        loadCnt.classList.add('active');
        //loadCnt.classList.add('visible');
    };

    service.hideContainer = function () {
        var self = this;
        self.hideLoadIcon();
        var loadCnt = document.querySelector('#loadContainer');
        loadCnt.classList.remove('active');
    };

    service.showAlert = function (title, text) {
        try {
            //MOBILE
            navigator.notification.alert(
                text, // message
                null, // callback
                title, // title
                'OK' // buttonName
            );
        } catch (exc) {
            //DESKTOP
            //				var alertPopup = $ionicPopup.alert({
            //					title: title,
            //					template: text,
            //				});
        }
    };

    service.showConfirm = function (title, text, onConfirm) {
        try {
            //MOBILE
            navigator.notification.confirm(
                text, // message
                onConfirm, // callback to invoke with index of button pressed
                title, // title
                ['Ja', 'Nee'] // buttonLabels
            );
        } catch (exc) {
            //DESKTOP
            console.log('show confirm!');
            var r = confirm(text);
            if (r == true) {
                onConfirm(true);
            } else {
                onConfirm(false);
            }
            //				var confirmPopup = $ionicPopup.confirm({
            //					title: title,
            //					template: text,
            //					cancelText: 'Nee', // String (default: 'Cancel'). The text of the Cancel button.
            //					okText: 'Ja' // String (default: 'OK'). The text of the OK button.
            //				});
            //				confirmPopup.then(function (res) {
            //					onConfirm(res);
            //				});
        }
    };

    service.isEmpty = function (value) { //value !== value checks for NaN values
        var empty = (value == undefined || value == null || value == "" || value !== value ||
            value == []);
        if (value instanceof Object) {
            empty = jQuery.isEmptyObject(value);
        }
        return empty;
    };

    service.checkAndFill = function (value, defaultValue, format) {
        //console.log('check: ' + value + ', default: ' + defaultValue + ', format: ' + format);
        var self = this;
        if (self.isEmpty(value)) {
            value = defaultValue;
        } else if (format != null && format != undefined) {
            value = format;
        }
        return value;
    };

    service.gotToday = function (key) {
        var date = new Date();
        var last = localStorage.getItem(key);
        if ((last == undefined || last == null) && last != date.toDateString()) {
            localStorage.setItem(key, date.toDateString());
            return false;
        } else {
            return true;
        }
    };

    service.selectTime = function (currTime, onSuccess, onError) {
        var self = this;
        datePicker.show({
            mode: 'time',
            date: currTime,
            is24Hour: true,
            androidTheme: 3
        }, function (date) {
            console.log('selected time: ' + JSON.stringify(date));
            if (date != undefined && date != null) {
                onSuccess(date);
            } else {
                onError(false);
            }
        }, function (err) {
            self.reportError(err);
            onError(err);
        });
    };

    service.selectDate = function (currDate, onSuccess, onError) {
        currDate = new Date(currDate);
        console.log('selected date in SRV: ' + currDate);
        var self = this;
        datePicker.show({
            mode: 'date',
            date: currDate,
            //					maxDate: new Date(),
            androidTheme: 3,
            //					allowFutureDates: false
        }, function (date) {
            console.log('selected date: ' + JSON.stringify(date));
            if (date != undefined && date != null) {
                onSuccess(date);
            } else {
                onError(false);
            }
        }, function (err) {
            self.reportError(err);
            onError(err);
        });
    };

    service.reportError = function (err) {
        console.log('AppSrv reportError: ' + err + ', JSON: ' + JSON.stringify(err));
        var self = this;
        var currDate = new Date();
        var currTime = currDate.getTime();
        if (!self.isEmpty(err)) {
            if (err.status == 401) {
                $state.go('intro.login');
                var newLoc = window.location.href.split("#")[0];
                window.location.href = newLoc;
            } else {
                //console.log('lastError: ' + lastError + ', minus: ' + (currTime - lastError));
                if (lastError == null || ((currTime - lastError) > 1000)) {
                    try {
                        self.hideContainer();
                        self.hideLoading();
                        if (!self.isEmpty(err)) {
                            if (err.indexOf('cordova is not defined') < 0 && err.indexOf('cordova undefined') < 0 && err.indexOf('cancel') < 0) {
                                lastError = currTime;
                                self.showAlert('Melding', 'Er is iets fout gegaan, probeer het opnieuw of herstart de app. Excuses.\n\n' + err + ', JSON: ' + JSON.stringify(err));
                            }
                        } else if (!self.isEmpty(err.data)) {
                            lastError = currTime;
                            if (err.data.indexOf('cordova is not defined') < 0 && err.data.indexOf('cordova undefined') < 0 && err.data.indexOf('cancel') < 0) {
                                self.showAlert('Melding', 'Er is iets fout gegaan, probeer het opnieuw of herstart de app. Excuses.\n\n' + err.data + ', JSON: ' + JSON.stringify(err.data));
                            }
                        }
                    } catch (error) {
                        lastError = currTime;
                        self.showAlert('Melding', 'Een nog onbekende fout is opgetreden, we verzoeken u de internetconnectie te controleren.\n\n' + error.data + ', ' + JSON.stringify(error.data) + ', ' + JSON.stringify(err));
                    }
                }
            }
        }
    };
}]);
