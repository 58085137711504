angular.module('SimonsSteigerbouwPlanning.services.ClientService', [])

.service('ClientService', ['$rootScope', '$timeout', '$http', '$cookieStore', '$location', 'UserService', 'UrlService', 'AppService', 'ApiService', 'LoginService', function($rootScope, $timeout, $http, $cookieStore, $location, UserService, UrlService, AppService, ApiService, LoginService) {
    var api = ApiService;				
    var service = this;
    var selectedClient;
    var activeClient;
    var addClientUrl = UrlService.addClientUrl;

    //calendar status (default)
    var currentDateValues = {
        "date": new Date(),
        "dateString": "Vandaag",
        "today": true
    };

    var searchWorker;
    var clientUrl = UrlService.ClientUrl;

    service.getDateValues = function () {
        return currentDateValues;
    };

    service.setDateValues = function (dateValues) {
        currentDateValues = dateValues
    };

    service.getClients = function(onSuccess) {
        return $http({
            method: 'get',
            url: api.getClientsEP() + "/all"
        }).then(function(data) {
            onSuccess(data.data);
        }, function(error){
            if (error.status == '401') {
                LoginService.logout();
                $location.path('/login');
            }
            console.log('Error: ', error);
        });
    };
    
    service.getClient = function(clientId, onSuccess) {
        return $http({
            method: 'get',
            url: api.getClientsEP() + "/" + clientId
        }).then(function(data) {
            onSuccess(data.data);
        }, function(error){
            if (error.status == '401') {
                LoginService.logout();
                $location.path('/login');
            }
            console.log('Error: ', error);
        })
    }

    service.getSelectedClient = function() {
        return service.selectedClient;
    }

    service.setSelectedClient = function(selectedClient) {
        console.log('Saving selectedClient: ', selectedClient);
        $cookieStore.put('selectedClient', selectedClient);
        service.selectedClient = selectedClient;
    }

    service.setActiveClient = function(activeClient) {
        console.log('Saving activeClient: ', activeClient);
        $cookieStore.put('activeClient', activeClient);
        service.activeClient = activeClient;
    }

    service.getActiveClient = function() {
        console.log('Getting active client: ', service.activeClient);
        return service.activeClient;
    }

    service.saveClient = function (client, isEdit, onSuccess, onError) {
        if (!isEdit) {
            this.addClient(client, function(response) {
                console.log('Successfully saved client');
            }, function(error) {
                if (error.status == '401') {
                    LoginService.logout();
                    $location.path('/login');
                } else {
                    console.log(error);
                }
            });
        } else {
            this.editClient(client, function(response) {
                console.log('Successfully edited client');
            }, function(error) {
                if (error.status == '401') {
                    LoginService.logout();
                    $location.path('/login');
                } else {
                    console.log(error);
                }
            });
        }
    }

    service.addClient = function (client, onSuccess, onError) {
        var self = this;
        console.log("Posting with ", client);
        UrlService.doPost(api.getClientsEP(), {
            "client": client
        }, function (res) {
            var addedClient = res.data;
            onSuccess(addedClient);
        }, function (err) {
            if (err.status == '401') {
                LoginService.logout();
                $location.path('/login');
            } else {
                console.log(err);
            }
        });
    };

    service.editClient = function (client, onSuccess, onError) {
        var self = this;
        UrlService.doPost(api.getClientsEP() + "/" + client.id, {
            "client": client
        }, function (res) {
            var addedItem = res.data;
            onSuccess(addedItem);
        }, function (err) {
            if (err.status == '401') {
                LoginService.logout();
                $location.path('/login');
            } else {
                console.log(err);
            }
        });
    };

    service.removeClient = function (list, data, onSuccess, onError) {
        var self = this;
        if (data.clientid != undefined && data.clientid != null) {
            data.id = data.clientid;
            console.log(data.id);
        }
        UrlService.doPost(removeClientUrl, {
            "clientid": data.id
        }, function (res) {
            onSuccess(res);
        }, function (err) {
            AppService.reportError(err);
            onError(err);
        });
    };

    service.getClientDetails = function (client, onSuccess, onError) {
        var id = client;
        var url = api.getClientsEP() + '/' + id;
        UrlService.doGet(url, function (res) {
            var detailedClient = res.data.client;
            service.setSelectedClient(detailedClient);
            onSuccess(detailedClient);
        }, function (res) {
            AppService.reportError(err);
            onError(res);
        });
    };

    service.destroyWorker = function () {
        if (searchWorker != undefined && searchWorker != null) {
            searchWorker.destroy();
        }
    };
}]);
