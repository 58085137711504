angular.module('SimonsSteigerbouwPlanning.services.ShoplistService', [])

.service('ShoplistService', ['$rootScope', '$location', 'UrlService', 'AppService', 'ApiService', 'LoginService', 'UrlService', function($rootScope, $location, UrlService, AppService, ApiService, LoginService, UrlService) {
    var api = ApiService;				
    var service = this;
    
    var getProductsUrl = UrlService.materialUrl;
    
    service.getProducts  = function(onSuccess) {
        UrlService.doGet(getProductsUrl, function(data) {
            onSuccess(data.data);
        }, function(error) {
            if (error.status == '401') {
                LoginService.logout();
                $location.path('/login');
            }
            console.log(error);
        });
    };
}]);
