angular.module('SimonsSteigerbouwPlanning.controllers.LoginController', [])

.controller('LoginController', ['$scope', '$rootScope', '$location', '$routeParams', 'NavigationService', 'LoginService', function($scope, $rootScope, $location, $routeParams, NavigationService, LoginService) {
    NavigationService.setTitle("Simons Steigerbouw");
    NavigationService.setPageName("Login");
    $rootScope.$broadcast('refreshUi');
    
    $scope.forgot = false;
    $scope.passwordSent = false;

    $scope.message = $routeParams.message;

    $scope.user = {};

    $scope.login = function () {
        $scope.user.app = 'admin';
        $rootScope.$broadcast('loading-start');
        LoginService.login($scope.user, function () {
            $location.path('#/items');
            $rootScope.$broadcast('loading-stop');
        }, function(errors) {
            $scope.errors = errors;
            $rootScope.$broadcast('loading-stop');
        });
    };

    $scope.forgotClicked = function() {
        delete $scope.message;
        delete $scope.passwordSent;
        delete $scope.errors;
        $scope.forgot = !$scope.forgot;
        MainController.setTitle("Wachtwoord vergeten");
    };

    $scope.sendPassword = function() {
        if($scope.forgotemail === undefined) return;
        $rootScope.$broadcast('loading-start');
        LoginService.forgotPassword($scope.forgotemail, function () {
            $scope.passwordSent = 'success';
            $rootScope.$broadcast('loading-stop');
        }, function(errors) {
            $scope.passwordSent = 'error';
            $rootScope.$broadcast('loading-stop');
        });
    };
}]);
