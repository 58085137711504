angular.module('SimonsSteigerbouwPlanning.controllers.MainController', [])

.controller('MainController', ['$scope', '$rootScope', '$location', 'SharedState', 'LoginService', 'NavigationService', 'ItemService', 'ClientService', function($scope, $rootScope, $location, SharedState, LoginService, NavigationService, ItemService, ClientService) {
    $scope.pageTitle = NavigationService.getPageTitle();
    $scope.pageName = NavigationService.getPageName();
    $scope.showNavbar = NavigationService.getShowNavbar();
    $scope.showBottomBar = NavigationService.getShowBottomBar();
    $scope.showLogout = NavigationService.getShowLogout();
    $scope.showBack = NavigationService.getShowBack();
    $scope.showSaveButton = NavigationService.getShowSaveButton();
    $scope.showEditButton = NavigationService.getShowEditButton();
    $scope.showAddButton = NavigationService.getShowAddButton();
    
    $scope.setTitle = function(newPageTitle) {
        $scope.pageTitle = newPageTitle;
        NavigationService.pageTitle = newPageTitle;
    };
    $scope.setPage = function(pageName) {
        $scope.pageName = pageName;
        NavigationService.setPageName(pageName);
    };
    $scope.getPageName = function() {
        return NavigationService.getPageName();
    };
    
    $scope.setShowNavbar = function(showNavbar) {
        $scope.showNavbar = showNavbar;
        NavigationService.setShowNavbar(showNavbar);
    };
    
    $scope.setShowBottomBar = function(showBottomBar) {
        $scope.showBottomBar = showBottomBar;
        NavigationService.setShowBottomBar(showBottomBar);
    };
    
    $scope.setShowLogout = function(showLogout) {
        this.showLogout = showLogout;
        NavigationService.setShowLogout(showLogout);
    };

    $scope.back = function() {
        console.log('Coming from: ', NavigationService.getPageName());
        if (NavigationService.getPageName() == 'Itemdetail') {
            $location.path('/items');
        } else if (NavigationService.getPageName() == 'Clientdetail') {
            $location.path('/clients');
        } else {
            console.log('Confused...');
        }
    };
    
    $scope.editPressed = function() {
        console.log('Coming from: ', NavigationService.getPageName());
        $rootScope.$broadcast('editClicked');
        if (NavigationService.getPageName() == 'Itemdetail') {
            $scope.showSaveButton = true;
            $scope.showEditButton = false;
        } else if (NavigationService.getPageName() == 'Clientdetail') {
            $scope.showSaveButton = true;
            $scope.showEditButton = false;
        } else {
            console.log('Confused');
        }
    };
    
    $scope.savePressed = function() {
        $rootScope.$broadcast('saveClicked');
        if (NavigationService.getPageName() == 'Itemdetail') {
            NavigationService.setTitle("Nieuw");
            $rootScope.$broadcast('refreshUi');
            $location.path('/items');
        } else if (NavigationService.getPageName() == 'Clientdetail') {
            NavigationService.setTitle("Nieuw");
            $rootScope.$broadcast('refreshUi');
            $location.path('/clients');
        } else {
            console.log('Confused');
        }
    };

    $scope.addPressed = function () {
        console.log('Coming from: ', NavigationService.getPageName());
        if (NavigationService.getPageName() == 'Itemlist') {
            ItemService.setSelectedItem({id: 0, name: "Nieuw"});
            ItemService.setSelectedClient(null);
            NavigationService.setTitle("Nieuw");
            NavigationService.setPageName("Nieuw");
            $rootScope.$broadcast('refreshUi');
            $location.path('/itemdetail/false');
        } else if (NavigationService.getPageName() == 'Clientlist') {
            ClientService.setSelectedClient({id: 0, voornaam: "Nieuw"});
            NavigationService.setTitle("Nieuw");
            NavigationService.setPageName("Nieuw");
            $rootScope.$broadcast('refreshUi');
            $location.path('/clientdetail/false');
        }
    };
    
    $scope.dateRequestPressed = function() {
        $rootScope.$broadcast('dateRequestList');
    };
    $scope.dateConstructionPressed = function() {
        $rootScope.$broadcast('dateConstructionList');
    };
    $scope.dateFinishPressed = function() {
        $rootScope.$broadcast('dateFinishList');
    };
    $scope.dateDeconstructPressed = function() {
        $rootScope.$broadcast('dateDeconstructList');
    };

    $scope.logout = function() {
        LoginService.logout();
        $location.path('/login');
        $scope.showLogout = false;
        $scope.showSidebar = false;
        $scope.showBack = false;
        NavigationService.setTitle("Login");
    };
    
    $rootScope.$on('refreshUi', function() {
        console.log('Refreshing UI');
        $scope.pageTitle = NavigationService.getPageTitle();
        $scope.pageName = NavigationService.getPageName();
        $scope.showNavbar = NavigationService.getShowNavbar();
        $scope.showBottomBar = NavigationService.getShowBottomBar();
        $scope.showLogout = NavigationService.getShowLogout();
        $scope.showBack = NavigationService.getShowBack();
        $scope.showEditButton = NavigationService.getShowEditButton();
        $scope.showSaveButton = NavigationService.getShowSaveButton();
        $scope.showAddButton = NavigationService.getShowAddButton();
    });
}]);