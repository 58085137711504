angular.module('SimonsSteigerbouwPlanning.controllers.ShoplistController', [])

.controller('ShoplistController', ['$scope', '$rootScope', '$document', '$q', '$http', 'NavigationService', 'ShoplistService', function($scope, $rootScope, $document, $q, $http, NavigationService, ShoplistService) {

    /*INIT PAGE SECTION*/
    NavigationService.setTitle("Weegschaal");
    NavigationService.setPageName("Weegschaal");
    $rootScope.$broadcast('refreshUi');
    
    $scope.isloading = true;
    $scope.loadingtext = '';
    $scope.$on('api-start', function(event, data) {
        $scope.loadingtext = data;
        $scope.isloading = true;
    });
    $scope.$on('api-done', function(event, data) {
        $timeout(function() {
            $scope.loadingtext = '';
            $scope.isloading = false;
        }, 300);
    });
    
    $rootScope.$on('refresh', function() {
        
    });

    $rootScope.$broadcast('shoplist-view');
    
    $scope.recalculate = function () {
        $scope.weight = 0;
        $scope.price = 0;
        $scope.products.forEach(function(product) {
            $scope.weight += product.weight * product.amount;
            $scope.price += product.price * product.amount;
        });
    }
    
    $scope.weight = 0;
    $scope.price = 0;
    
    ShoplistService.getProducts(function(products) {
        $scope.products = products;
        $scope.products.forEach(function(product) {
            product.amount = 0;
        });
        console.log('Products set: ', products);
        $rootScope.$broadcast('loading-stop');
    }, function(errors) {
        $scope.errors = errors;
        console.log('Error: ', errors);
        $rootScope.$broadcast('loading-stop');
    });
    
    $scope.addItem = function (materialName) {
        $scope.products.some(function(product) {
            if (product.name == materialName) {
                product.amount++;
                $scope.recalculate();
                return true;
            }
        });
    }
    $scope.removeItem = function (materialName) {
        $scope.products.some(function(product) {
            if (product.name == materialName) {
                if (product.amount != 0) {
                    product.amount--;
                    $scope.recalculate();
                }
                return true;
            }
        });
    }
    $scope.removeAllItems = function (materialName) {
        $scope.products.some(function(product) {
            if (product.name == materialName) {
                product.amount = 0;
                $scope.recalculate();
                return true;
            }
        });
    }
        
    $scope.logout = function() {
        LoginService.logout();
        $location.path('/login');
        $scope.showLogout = false;
        NavigationService.setShowLogout(false);
        $scope.showBack = false;
        NavigationService.setShowBack(false);
    };
}]);
