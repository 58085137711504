angular.module('SimonsSteigerbouwPlanning.controllers.ItemController', [])

.controller('ItemController', ['$scope', '$rootScope', '$timeout', 'ngDialog', 'NavigationService', 'UserService', '$location', 'LoginService', 'ItemService', function($scope, $rootScope, $timeout, ngDialog, NavigationService, UserService, $location, LoginService, ItemService) {

    /*INIT PAGE SECTION*/
    NavigationService.setTitle("Steigers");
    NavigationService.setPageName("Itemlist");
    $rootScope.$broadcast('refreshUi');
    //$scope.tabName = ItemService.getTabName();

    $scope.isloading = true;
    $scope.loadingtext = '';
    $scope.$on('api-start', function(event, data) {
        $scope.loadingtext = data;
        $scope.isloading = true;
    });
    $scope.$on('api-done', function(event, data) {
        $timeout(function() {
            $scope.loadingtext = '';
            $scope.isloading = false;
        }, 300);
    });
    $rootScope.$on('refresh', function() {
        $scope.refreshItems();
    });

    $scope.items = [];

    $rootScope.$on('dateRequestList', function() {
        ItemService.setTabName("request");
        //$scope.tabName = "request";
        $scope.refreshItems();
    });
    $rootScope.$on('dateConstructionList', function() {
        ItemService.setTabName("construction");
        //$scope.tabName = "construction";
        $scope.refreshItems();
    });
    $rootScope.$on('dateFinishList', function() {
        ItemService.setTabName("finish");
        //$scope.tabName = "finish";
        $scope.refreshItems();
    });
    $rootScope.$on('dateDeconstructList', function() {
        ItemService.setTabName("deconstruct");
        //$scope.tabName = "deconstruct";
        $scope.refreshItems();
    });

    $scope.refreshItems = function() {
        $rootScope.$broadcast('loading-start');
        ItemService.getItems(function(items) {
            $scope.items = items;
            $rootScope.$broadcast('loading-stop');
        }, function(errors) {
            $scope.errors = errors;
            $rootScope.$broadcast('loading-stop');
        });
    };
    $scope.refreshItems();

    $rootScope.$broadcast('item-view');

    /*ORDER COLUMN SECTION*/
    $scope.order = 'lastname';
    $scope.asc = false;
    $scope.setOrder = function (col) {
        if($scope.order == col) {
            $scope.asc = !$scope.asc;
        } else {
            $scope.order = col;
            $scope.asc = true;
        }
    };

    $scope.openItemDetail = function (item) {
        ItemService.getItemDetails(item.id, function(current) {
            console.log('Going to details with item: ', item);
            ItemService.setSelectedItem(item);
            NavigationService.setTitle(item.name);
            NavigationService.setShowSaveButton(false);
            NavigationService.setShowEditButton(true);
            //$rootScope.$broadcast('refreshUi');
            $location.path('/itemdetail/true');
        });
    };

    $scope.editDateRequest = function (item, $event) {
        $event.stopPropagation();
        ItemService.getItemDetails(item.id, function(current) {
            console.log('Editting date request from item: ', item);
            ItemService.setSelectedItem(item);
            NavigationService.setTitle(item.name);
            NavigationService.setShowSaveButton(false);
            NavigationService.setShowEditButton(true);
            //$rootScope.$broadcast('refreshUi');
            $location.path('/itemdetail/false/date_request');
        });
    };

    $scope.editDateConstruction = function (item, $event) {
        $event.stopPropagation();
        ItemService.getItemDetails(item.id, function(current) {
            console.log('Editting date construction from item: ', item);
            ItemService.setSelectedItem(item);
            NavigationService.setTitle(item.name);
            NavigationService.setShowSaveButton(true);
            NavigationService.setShowEditButton(false);
            //$rootScope.$broadcast('refreshUi');
            $location.path('/itemdetail/false/date_construction');
        });
    };

    $scope.editDateFinish = function (item, $event) {
        $event.stopPropagation();
        ItemService.getItemDetails(item.id, function(current) {
            console.log('Editting date finish from item: ', item);
            ItemService.setSelectedItem(item);
            NavigationService.setTitle(item.name);
            NavigationService.setShowSaveButton(true);
            NavigationService.setShowEditButton(false);
            //$rootScope.$broadcast('refreshUi');
            $location.path('/itemdetail/false/date_finish');
        });
    };

    $scope.editDateDeconstruct = function (item, $event) {
        $event.stopPropagation();
        ItemService.getItemDetails(item.id, function(current) {
            console.log('Editting date deconstruct from item: ', item);
            ItemService.setSelectedItem(item);
            NavigationService.setTitle(item.name);
            NavigationService.setShowSaveButton(true);
            NavigationService.setShowEditButton(false);
            //$rootScope.$broadcast('refreshUi');
            $location.path('/itemdetail/false/date_deconstruct');
        });
    };
        
    $scope.logout = function() {
        LoginService.logout();
        $location.path('/login');
        $scope.showLogout = false;
        NavigationService.setShowLogout(false);
        $scope.showBack = false;
        NavigationService.setShowBack(false);
    };
}]);
