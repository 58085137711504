angular.module('SimonsSteigerbouwPlanning.services.NavigationService', [])

.service('NavigationService', ['$location', 'LoginService', function($location, LoginService) {
    var pageTitle = "Simons Steigerbouw";
    var pageName = "";
    var showNavbar = true;
    var showBottomBar = false;
    var showLogout = false;
    var showBack = false;
    var showAddButton = false;
    var showEditButton = false;
    var showSaveButton = false;
    
    this.getPageTitle = function() {
        return this.pageTitle;
    }
    this.setTitle = function(newPageTitle) {
        this.pageTitle = newPageTitle;
    };

    this.getPageName = function() {
        return this.pageName;
    };
    this.setPageName = function(newPageName) {
        this.pageName = newPageName;
        if (this.pageName == "Login") {
            this.setShowBottomBar(false);
            this.setShowNavbar(true);
            this.setShowLogout(false);
            this.setShowBack(false);
            this.setShowAddButton(false);
            this.setShowEditButton(false);
            this.setShowSaveButton(false);
        } else if (this.pageName == "Itemlist") {
            this.setShowNavbar(true);
            this.setShowBottomBar(true);
            this.setShowLogout(true);
            this.setShowBack(false);
            this.setShowAddButton(true);
            this.setShowEditButton(false);
            this.setShowSaveButton(false);
        } else if (this.pageName == "Clientlist") {
            this.setShowNavbar(true);
            this.setShowBottomBar(false);
            this.setShowLogout(true);
            this.setShowBack(false);
            this.setShowAddButton(true);
            this.setShowEditButton(false);
            this.setShowSaveButton(false);
        } else if (this.pageName == "Itemdetail") {
            this.setShowNavbar(true);
            this.setShowBottomBar(false);
            this.setShowLogout(false);
            this.setShowBack(true);
            this.setShowAddButton(false);
            this.setShowEditButton(true);
            this.setShowSaveButton(false);
        } else if (this.pageName == "Clientdetail") {
            this.setShowNavbar(true);
            this.setShowBottomBar(false);
            this.setShowLogout(false);
            this.setShowBack(true);
            this.setShowAddButton(false);
            this.setShowEditButton(true);
            this.setShowSaveButton(false);
        } else if (this.pageName == "Nieuw") {
            this.setShowNavbar(true);
            this.setShowBottomBar(false);
            this.setShowLogout(false);
            this.setShowBack(true);
            this.setShowAddButton(false);
            this.setShowEditButton(false);
            this.setShowSaveButton(true);
        } else if (this.pageName == "Weegschaal") {
            this.setShowNavbar(true);
            this.setShowBottomBar(false);
            this.setShowLogout(true);
            this.setShowBack(false);
            this.setShowAddButton(false);
            this.setShowEditButton(false);
            this.setShowSaveButton(false);
        } else {
            console.log('Confused...');
        }
    };
    
    this.getShowNavbar = function() {
        return this.showNavbar;
    };
    this.setShowNavbar = function(showNavbar) {
        this.showNavbar = showNavbar;
    };
    
    this.getShowBottomBar = function() {
        return this.showBottomBar;
    };
    this.setShowBottomBar = function(showBottomBar) {
        this.showBottomBar = showBottomBar;
    };
    
    this.getShowLogout = function() {
        return this.showLogout;
    };
    this.setShowLogout = function(showLogout) {
        this.showLogout = showLogout;
    };

    this.getShowBack = function() {
        return this.showBack;
    };
    this.setShowBack = function(showBack) {
        this.showBack = showBack;
    };
    
    this.getShowAddButton = function() {
        return this.showAddButton;
    };
    this.setShowAddButton = function(showAddButton) {
        this.showAddButton = showAddButton;
    };
    
    this.getShowEditButton = function() {
        return this.showEditButton;
    };
    this.setShowEditButton = function(showEditButton) {
        this.showEditButton = showEditButton;
    };
    
    this.getShowSaveButton = function() {
        return this.showSaveButton;
    };
    this.setShowSaveButton = function(showSaveButton) {
        this.showSaveButton = showSaveButton;
    };
    
    this.logout = function() {
        LoginService.logout();
        $location.path('/login');
        this.showLogout = false;
        this.showBack = false;
    };
}]);
