angular.module('SimonsSteigerbouwPlanning.services.ItemService', [])

.service('ItemService', ['$rootScope', '$timeout', '$http', '$cookieStore', '$location', 'UserService', 'UrlService', 'AppService', 'ApiService', 'LoginService', function($rootScope, $timeout, $http, $cookieStore, $location, UserService, UrlService, AppService, ApiService, LoginService) {
    var api = ApiService;				
    var service = this;
    var selectedItem;
    var activeItem;
    var selectedClient;
    var activeClient;
    var addItemUrl = UrlService.addItemUrl;
    var itemPhotoEP = UrlService.itemPhotoEP;
    var getItemPhotoEP = UrlService.getItemPhotoEP;
    var tabName = "request";
    service.tabName = tabName;

    //calendar status (default)
    var currentDateValues = {
        "date": new Date(),
        "dateString": "Vandaag",
        "today": true
    };

    var searchWorker;
    var itemUrl = UrlService.itemUrl;

    service.getDateValues = function () {
        return currentDateValues;
    };

    service.setDateValues = function (dateValues) {
        currentDateValues = dateValues
    };

    service.getItems = function(onSuccess) {
        return $http({
            method: 'get',
            url: api.getItemsEP() + "/" + service.getTabName()
        }).then(function(data) {
            onSuccess(data.data);
        }, function(error){
            if (error.status == '401') {
                LoginService.logout();
                $location.path('/login');
            }
        });
    };

    service.getSelectedItem = function() {
        console.log('Getting selected item: ', service.selectedItem);
        return service.selectedItem;
    }

    service.setSelectedItem = function(selectedItem) {
        console.log('Saving selectedItem: ', selectedItem);
        $cookieStore.put('selectedItem', selectedItem);
        service.selectedItem = selectedItem;
    }

    service.setActiveItem = function(activeItem) {
        console.log('Saving activeItem: ', activeItem);
        $cookieStore.put('activeItem', activeItem);
        service.activeItem = activeItem;
    }

    service.getActiveItem = function() {
        console.log('Getting active item: ', service.activeItem);
        return service.activeItem;
    }

    service.getSelectedClient = function() {
        console.log('Getting selected client: ', service.selectedClient);
        return service.selectedClient;
    }

    service.setSelectedClient = function(selectedClient) {
        console.log('Saving selectedClient', selectedClient);
        $cookieStore.put('selectedClient', selectedClient);
        service.selectedClient = selectedClient;
    }

    service.getActiveClient = function() {
        console.log('Getting active client: ', service.activeClient);
        return service.activeClient;
    }

    service.setActiveClient = function(activeClient) {
        console.log('Saving activeClient: ', activeClient);
        $cookieStore.put('activeClient', activeClient);
        service.activeClient = activeClient;
    }

    service.saveItem = function (item, client, isEdit, onSuccess, onError) {
        if (!isEdit) {
            this.addItem(item, client, function(response) {
                console.log('Successfully saved item');
            }, function(error) {
                if (error.status == '401') {
                    LoginService.logout();
                    $location.path('/login');
                } else {
                    console.log(error);
                }
            });
        } else {
            this.editItem(item, client, function(response) {
                console.log('Successfully saved edited item');
            }, function(error) {
                if (error.status == '401') {
                    LoginService.logout();
                    $location.path('/login');
                } else {
                    console.log(error);
                }
            });
        }
    }

    service.addItem = function (item, client, onSuccess, onError) {
        var self = this;
        console.log("Posting with ", item);
        UrlService.doPost(api.getItemsEP(), {
            "client": client,
            "item": item
        }, function (res) {
            var addedItem = res.data;
            onSuccess(addedItem);
        }, function (err) {
            if (err.status == '401') {
                LoginService.logout();
                $location.path('/login');
            } else {
                console.log(err);
            }
        });
    };

    service.editItem = function (item, client, onSuccess, onError) {
        //var self = this;
        UrlService.doPost(api.getItemsEP() + "/" + item.id, {
            "client": client,
            "item": item
        }, function (res) {
            var addedItem = res.data;
            onSuccess(addedItem);
        }, function (err) {
            if (err.status == '401') {
                LoginService.logout();
                $location.path('/login');
            } else {
                console.log(err);
            }
        });
    };

    service.removeItem = function (list, data, onSuccess, onError) {
        var self = this;
        if (data.itemid != undefined && data.itemid != null) {
            data.id = data.itemid;
        }
        UrlService.doPost(removeItemUrl, {
            "itemid": data.id
        }, function (res) {
            onSuccess(res);
        }, function (err) {
            AppService.reportError(err);
            onError(err);
        });
    };

    service.getItemDetails = function (item, onSuccess, onError) {
        console.log("Pressed item: " + JSON.stringify(item));
        var id = item;
        var url = api.getItemsEP() + '/' + id;
        UrlService.doGet(url, function (res) {
            var detailedItem = res.data ;
            console.log('Found item: ', detailedItem);
            console.log('And client: ', detailedItem.client);
            service.setSelectedClient(detailedItem.client);
            onSuccess(detailedItem);
        }, function (res) {
            AppService.reportError(err);
            onError(res);
        });
    };
    
    service.getItemImage = function (id, onSuccess, onError) {
        UrlService.doGet(getItemPhotoEP + id, function (res) {
            onSuccess(res.image);
        }, function (err) {
            AppService.reportError(err);
            onError(err);
        })
    };
    
    service.uploadPhoto = function (id, file, onSuccess, onError) {
        UrlService.doPost(itemPhotoEP + id, file, function (res) {
            console.log('Image uploaded');
            onSuccess(res);
        }, function (err) {
            AppService.reportError(err);
            onError(err);
        });
    };

    service.destroyWorker = function () {
        if (searchWorker != undefined && searchWorker != null) {
            searchWorker.destroy();
        }
    };
    
    service.getTabName = function() {
        return service.tabName;
    };
    service.setTabName = function(newTabName) {
        service.tabName = newTabName;
    }
}]);
