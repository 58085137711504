angular.module('SimonsSteigerbouwPlanning.controllers.ItemDetailsController', [])

.controller('ItemDetailsController', ['$scope', '$rootScope', '$routeParams', '$location', 'ngDialog', 'Upload', 'UserService', 'ItemService', 'ClientService', 'NavigationService', 'AppService', function($scope, $rootScope, $routeParams, $location, ngDialog, Upload, UserService, ItemService, ClientService, NavigationService, AppService) {
    NavigationService.setPageName("Itemdetail");
    $rootScope.$broadcast('refreshUi');

    $scope.readMode = $routeParams.readonly == 'true';
    console.log('Selected Edit: ', $routeParams.selectedEdit);
    $scope.selectedEdit = $routeParams.selectedEdit;
    $scope.activeUser = UserService.getActiveUser();
    $scope.selectedItem = ItemService.getSelectedItem();
    if (!$scope.selectedItem) {
        $location.path('/items');
    };
    
    ClientService.getClients(function(clients) {
        $scope.clients = clients;
        if ($scope.selectedItem.client_id) {
            ClientService.getClient($scope.selectedItem.client_id, function(client) {
                var i=0, len=clients.length;
                for (; i<len; i++) {
                    if (clients[i].id == $scope.selectedItem.client_id) {
                        $scope.selectedClient = clients[i];
                        break;
                    }
                }
                console.log('Setting selected client: ', $scope.selectedClient);
                $rootScope.$broadcast('loading-stop');
            }, function(errors) {
                $scope.errors = errors;
                console.log('Error: ', errors);
                $rootScope.$broadcast('loading-stop');
            });
        } else {
            console.log('Not fetching a client');
        }
        $rootScope.$broadcast('loading-stop');
    }, function(errors) {
        $scope.errors = errors;
        console.log('Error: ', errors);
        $rootScope.$broadcast('loading-stop');
    });

    if ($scope.selectedItem.date_request) {
        $scope.selectedItem.date_request = new Date($scope.selectedItem.date_request);
    }
    if ($scope.selectedItem.date_construction) {
        $scope.selectedItem.date_construction = new Date($scope.selectedItem.date_construction);
    }
    if ($scope.selectedItem.date_finish) {
        $scope.selectedItem.date_finish = new Date($scope.selectedItem.date_finish);
    }
    if ($scope.selectedItem.date_deregistration) {
        $scope.selectedItem.date_deregistration = new Date($scope.selectedItem.date_deregistration);
    }
    
    $scope.datepickeroptions = {
        format: "DD.MM.YYYY" //,
        //locale: "nl"
    };
    
    if ($scope.selectedItem.price && $scope.selectedItem.price != '') {
        $scope.selectedItem.price = parseFloat($scope.selectedItem.price);
    };
    if ($scope.selectedItem.price_rental && $scope.selectedItem.price_rental != '') {
        $scope.selectedItem.price_rental = parseFloat($scope.selectedItem.price_rental);
    };
    
    if ($scope.selectedItem) {
        ItemService.getItemImage($scope.selectedItem.id, function(image) {
            $scope.selectedItem.image = image;
        }, function(errors) {
            Scope.errors = errors;
            console.log('Error: ', errors);
            $rootScope.$broadcast('loading-stop');
        });
    }
    
    console.log('Selected item: ' + JSON.stringify($scope.selectedItem));
    
    $scope.uploadFiles = function(file, errFiles) {
        $scope.errFile = errFiles && errFiles[0];
        if (file && $scope.selectedItem) {
            console.log('Uploading photo: ', file);
            ItemService.uploadPhoto($scope.selectedItem.id, file, function(current) {
                console.log('Success' );
            }, function (error) {
                console.log('No luck. ', error);
            });
        }
    };

    $scope.refresh = function () {

    };
    
    $rootScope.$on('editClicked', function() {
        $scope.readMode = !$scope.readMode;
    });
    $rootScope.$on('saveClicked', function() {
        console.log('Save clicked in itemdetail');
        $scope.selectedItem.client = $scope.selectedClient;
        ItemService.saveItem($scope.selectedItem);
    });
    
    if ($scope.selectedEdit !== null && $scope.selectedEdit == 'date_request') {
        console.log('Focussing Date Request');
         $scope.$broadcast('dateRequestEdit');
    } else if ($scope.selectedEdit !== null && $scope.selectedEdit == 'date_construction') {
        console.log('Focussing Date Construction');
        $scope.$broadcast('dateConstructionEdit');
    } else if ($scope.selectedEdit !== null && $scope.selectedEdit == 'date_finish') {
        console.log('Focussing Date Finish');
         $scope.$broadcast('dateFinishEdit');
    } else if ($scope.selectedEdit !== null && $scope.selectedEdit == 'date_deconstruct') {
        console.log('Focussing Date Deconstruct');
        $scope.$broadcast('dateDeconstructEdit');
    }    
}]);
