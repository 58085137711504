angular.module('SimonsSteigerbouwPlanning.controllers.UserController', [])

.controller('UserController', ['$scope', '$rootScope', '$timeout', 'ngDialog', 'UserService', function($scope, $rootScope, $timeout, ngDialog, UserService) {
    /*INIT PAGE SECTION*/
    (function () {
        $scope.isloading = true;
        $scope.loadingtext = '';
        $scope.$on('api-start', function(event, data) {
            $scope.loadingtext = data;
            $scope.isloading = true;
        });
        $scope.$on('api-done', function(event, data) {
            $timeout(function() {
                $scope.loadingtext = '';
                $scope.isloading = false;
            }, 300);
        });
        $rootScope.$on('refresh', function() {
            $scope.refreshUsers();
        });

        $scope.users = [];

        $scope.refreshUsers = function() {
            $rootScope.$broadcast('loading-start');
            UserService.getAllUsers(function() {});
            UserService.getUsers(function(users) {
                $scope.users = users;
                $rootScope.$broadcast('loading-stop');
            });
        };
        $scope.refreshUsers();

        $rootScope.$broadcast('user-view');
    })();


    /*ORDER COLUMN SECTION*/
    (function() {
        $scope.order = 'lastname';
        $scope.asc = false;
        $scope.setOrder = function (col) {
            if($scope.order == col) {
                $scope.asc = !$scope.asc;
            } else {
                $scope.order = col;
                $scope.asc = true;
            }
        };
    })();


    /*DIALOG SECTION*/
    (function() {
        $scope.openUserDetail = function (user) {
            UserService.getUser(user.id, function(current) {
                UserService.activeUser = current;
                openDialog('Gebruiker details', 'edit');
            }); //angular.copy(user);
        };

        $scope.newUserBtnClicked = function () {
            UserService.activeUser = {};
            openDialog('Nieuwe gebruiker', 'new');
        };

        $scope.userRolesStr = function (user) {
            var roles = [];
            for (var k in user.roles) {
                roles.push(user.roles[k].name);
            }
            return roles.join(", ");
        };

        $scope.userBodyfeaturesStr = function (user) {
            var bodyfeatures = [];
            for (var k in user.bodyfeatures) {
                bodyfeatures.push(user.bodyfeatures[k].name);
            }
            return bodyfeatures.join(", ");
        };

        var openDialog = function (dialogTitle, action) {
            ngDialog.open({
                template: 'partials/edituser.html',
                controller: 'userDialogController',
                data: {
                    dialogTitle: dialogTitle,
                    action: action
                },
                cache: false
            });
        };
    })();    
}]);
