angular.module('SimonsSteigerbouwPlanning.controllers.ClientDetailsController', [])

.controller('ClientDetailsController', ['$scope', '$rootScope', '$routeParams', '$location', 'ngDialog', 'UserService', 'ClientService', 'ItemService', 'NavigationService', 'AppService', function($scope, $rootScope, $routeParams, $location, ngDialog, UserService, ClientService, ItemService, NavigationService, AppService) {
    NavigationService.setPageName("Clientdetail");
    $rootScope.$broadcast('refreshUi');

    $scope.readMode = $routeParams.readonly == 'true';
    $scope.activeUser = UserService.getActiveUser();
    $scope.selectedClient = ClientService.getSelectedClient();
    if (!$scope.selectedClient) {
        $location.path('/clients');
    } else {
        $scope.items = $scope.selectedClient.items;
    }

    $scope.refresh = function () {

    };

    $scope.openItemDetail = function (item) {
        ItemService.getItemDetails(item.id, function(current) {
            console.log('Going to details with item: ', item);
            ItemService.setSelectedItem(item);
            NavigationService.setTitle(item.name);
            NavigationService.setShowSaveButton(false);
            NavigationService.setShowEditButton(true);
            //$rootScope.$broadcast('refreshUi');
            $location.path('/itemdetail/true');
        });
    };

    $scope.editDateRequest = function (item, $event) {
        $event.stopPropagation();
        ItemService.getItemDetails(item.id, function(current) {
            console.log('Editting date request from item: ', item);
            ItemService.setSelectedItem(item);
            NavigationService.setTitle(item.name);
            NavigationService.setShowSaveButton(false);
            NavigationService.setShowEditButton(true);
            //$rootScope.$broadcast('refreshUi');
            $location.path('/itemdetail/false/date_request');
        });
    };

    $scope.editDateConstruction = function (item, $event) {
        $event.stopPropagation();
        ItemService.getItemDetails(item.id, function(current) {
            console.log('Editting date construction from item: ', item);
            ItemService.setSelectedItem(item);
            NavigationService.setTitle(item.name);
            NavigationService.setShowSaveButton(true);
            NavigationService.setShowEditButton(false);
            //$rootScope.$broadcast('refreshUi');
            $location.path('/itemdetail/false/date_construction');
        });
    };

    $scope.editDateFinish = function (item, $event) {
        $event.stopPropagation();
        ItemService.getItemDetails(item.id, function(current) {
            console.log('Editting date finish from item: ', item);
            ItemService.setSelectedItem(item);
            NavigationService.setTitle(item.name);
            NavigationService.setShowSaveButton(true);
            NavigationService.setShowEditButton(false);
            //$rootScope.$broadcast('refreshUi');
            $location.path('/itemdetail/false/date_finish');
        });
    };

    $scope.editDateDeconstruct = function (item, $event) {
        $event.stopPropagation();
        ItemService.getItemDetails(item.id, function(current) {
            console.log('Editting date deconstruct from item: ', item);
            ItemService.setSelectedItem(item);
            NavigationService.setTitle(item.name);
            NavigationService.setShowSaveButton(true);
            NavigationService.setShowEditButton(false);
            //$rootScope.$broadcast('refreshUi');
            $location.path('/itemdetail/false/date_deconstruct');
        });
    };
    
    $rootScope.$on('editClicked', function() {
        $scope.readMode = !$scope.readMode;
    });
    $rootScope.$on('saveClicked', function() {
        ClientService.saveClient($scope.selectedClient);
    });
    
}]);
