angular.module('SimonsSteigerbouwPlanning', [
    'ngRoute',
    'mobile-angular-ui',
    'ngAria',
    'ngMaterial',
    'ngDialog',
    'angular-storage',
    'angular-jwt',
    'ngCookies',
    'ngAnimate',
    'angular-loading-bar',
    'ae-datetimepicker',
    'ngFileUpload',
    'ng-currency',
    'SimonsSteigerbouwPlanning.directives.FocusOnDirecive',
    'SimonsSteigerbouwPlanning.directives.ResizeInputDirective',
    'SimonsSteigerbouwPlanning.controllers.MainController',
    'SimonsSteigerbouwPlanning.controllers.LoginController',
    'SimonsSteigerbouwPlanning.controllers.ItemController',
    'SimonsSteigerbouwPlanning.controllers.ItemDetailsController',
    'SimonsSteigerbouwPlanning.controllers.ClientController',
    'SimonsSteigerbouwPlanning.controllers.ClientDetailsController',
    'SimonsSteigerbouwPlanning.controllers.ShoplistController',
    'SimonsSteigerbouwPlanning.controllers.UserController',
    'SimonsSteigerbouwPlanning.services.NavigationService',
    'SimonsSteigerbouwPlanning.services.LoginService',
    'SimonsSteigerbouwPlanning.services.ApiService',
    'SimonsSteigerbouwPlanning.services.AppService',
    'SimonsSteigerbouwPlanning.services.ItemService',
    'SimonsSteigerbouwPlanning.services.ClientService',
    'SimonsSteigerbouwPlanning.services.ShoplistService',
    'SimonsSteigerbouwPlanning.services.UserService',
    'SimonsSteigerbouwPlanning.services.UrlService'
])

.config(['$routeProvider', function($routeProvider) {
  "use strict";
  $routeProvider
        .when('/', {
            templateUrl:'items.html',
            controller: 'ItemController'
        })
        .when('/login', {
            templateUrl:'login.html',
            controller: 'LoginController'
        })
        .when('/items', {
            templateUrl: 'items.html',
            controller: 'ItemController'
        })
        .when('/itemdetail/:readonly/:selectedEdit?', {
            templateUrl: 'itemdetail.html',
            controller: 'ItemDetailsController'
        })
        .when('/clients', {
            templateUrl: 'clients.html',
            controller: 'ClientController'
        })
        .when('/shoplist', {
            templateUrl: 'shoplist.html',
            controller: 'ShoplistController'
        })
        .when('/clientdetail/:readonly', {
            templateUrl: 'clientdetail.html',
            controller: 'ClientDetailsController'
        })
        .otherwise({
            redirectTo: '/'
        });
}])

/*.config(['$httpProvider', 'jwtInterceptorProvider', function ($httpProvider, jwtInterceptorProvider) {
    "use strict";
    jwtInterceptorProvider.tokenGetter = function(store) {
        return store.get('jwt');
    };
    $httpProvider.interceptors.push('jwtInterceptor');
}])*/

.config(function ($httpProvider, jwtOptionsProvider, jwtInterceptorProvider) {
    "use strict";
    console.log('Configuring JWT');
    jwtOptionsProvider.config({
        whiteListedDomains: ['localhost', 'planning.simonssteigerbouw.nl'],
        /*tokenGetter: function(store) {
            return store.get('jwt');
        },*/
        unauthenticatedRedirectPath: '/'
    });
    jwtInterceptorProvider.tokenGetter = function(store) {
        return store.get('jwt');
    };
    $httpProvider.interceptors.push('jwtInterceptor');
})

.config(function(cfpLoadingBarProvider) {
    cfpLoadingBarProvider.includeSpinner = false;
    return cfpLoadingBarProvider;
})

// Needed so when using a forward slash in the URL, this won't get encoded to %2F. 
// This will remove the bang (!) from the ULR.
.config(['$locationProvider', function($locationProvider) {
  $locationProvider.hashPrefix('');
}])

// Needed so that tel can be used in aHref.
.config(['$compileProvider', function($compileProvider) {
  $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|file|tel):/);
}])

.run(function(authManager) {
    // Check auth on every refresh
    authManager.checkAuthOnRefresh();
    // Redirect the user to the website configured above when API returns a 401.
    //authManager.redirectWhenUnauthenticated();
})

.run(['$rootScope', 'LoginService', function($rootScope, LoginService) {
	$rootScope.$on("$routeChangeStart", function (event, next) {
        if (next) {
            var basePage = window.location.href.split("/#")[0];
            var nextPage = window.location.href.split("#")[1];
            var loginPage = nextPage === "/login";
            var itemsPage = nextPage === "/items";
            var clientsPage = nextPage === "/clients";
            if (LoginService.isLoggedIn()) {
                if (loginPage) {
                    window.location.href = basePage + '#/items';
                } else if (itemsPage && $rootScope.didRefresh != true){
                    $rootScope.didRefresh = true;
                    window.location.href = basePage + '#/items';
                } else if (clientsPage && $rootScope.didRefresh != true) {
                    $rootScope.didRefresh = true;
                    window.location.href = basePage + '#/clients';
                } else {
                    $rootScope.didRefresh = false;
                }
            } else if (!loginPage) {
                window.location.href = basePage + '#/login';
            }
        }
    });
    
  $rootScope.$on("cfbLoadingBar:loaded", $rootScope.ready);
  $rootScope.$on("cfbLoadingBar:loading", $rootScope.loading);
}]);