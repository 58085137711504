angular.module('SimonsSteigerbouwPlanning.services.UserService', [])

.service('UserService', ['$rootScope', '$http', 'ApiService', 'LoginService', '$cookieStore', function($rootScope, $http, ApiService, LoginService, $cookieStore) {
    var api = ApiService;
    var service = this;
    var users = [];
    var allUsers = [];
    var selectedUser;
    var activeUser;

    service.getSelectedUser = function() {
        return $cookieStore.get('selectedUser');
    }

    service.setSelectedUser = function(selectedUser) {
        $cookieStore.put('selectedUser', selectedUser);
        service.selectedUser = selectedUser;
    }

    service.getActiveUser = function() {
        return LoginService.getLoggedInUser();
    }

    service.getUsers = function(onSuccess) {
        return $http({
            method: 'get',
            url: api.getUserEP() + "/all"
        }).then(function(data) {
            users = data;
            onSuccess(data);
        });
    };

    service.getAllUsers = function(onSuccess) {
        return $http({
            method: 'get',
            url: api.getUserEP() + "/all?includeDeleted"
        }).then(function(data) {
            allUsers = data;
            onSuccess(data);
        })
        .catch(function (err) {
            console.log(err);
        });;
    };

    service.getUser = function(id, onSuccess) {
        return $http({
            method: 'get',
            url: api.getUserEP() + "/" + id
        }).then(function(res) {
            onSuccess(res);
        });
    };

    service.getNotes = function (userid, onSuccess) {
        return $http({
            method: 'get',
            url: api.getUserEP() + "/notes/" + userid
        }).then(function (res) {
            onSuccess(res);
        })
    }

    service.saveUser = function(user, isEdit, onSuccess) {
        var method = 'post';
        var url = api.getUserEP();
        if(isEdit) {
            method = 'put';
            url += '/' + user.id;
        }
        return $http({
            method: method,
            data: {user: user},
            url: url
        }).then(onSuccess);
    };

    service.saveNote = function(note, isEdit, onSuccess) {
        var method = 'post';
        var url = api.getUserEP() + '/note';
        note.user_id = service.selectedUser.id;
        note.entryuser = LoginService.getLoggedInUser().id;
        if(isEdit) {
            method = 'put';
            url += '/' + note.id;
        }
        return $http({
            method: method,
            data: {note: note},
            url: url
        }).then(function (res) {
            onSuccess(res);
        }, function (res) {
            onError(res);
        });
    };

    service.deleteUser = function(user, onSuccess) {
        return $http({
            method: 'delete',
            url: api.getUserEP() + '/' + user.id
        }).then(onSuccess);
    };

    service.deleteNote = function(note, onSuccess) {
        return $http({
            method: 'delete',
            url: api.getUserEP() + '/note/' + note.id
        }).then(onSuccess);
    };

    service.isUniqueUsername = function(username) {
        var usernameL = username.toLowerCase();
        for(var k in allUsers) {
            var u = allUsers[k];
        if (u.id == this.activeUser.id) {
            break;
        }
        if(u.username.toLowerCase() == usernameL)
            return false;
        }
        return true;
    };

    service.isUniqueEmail = function(email) {
      var emailL = email.toLowerCase();
        for(var k in allUsers) {
            var u = allUsers[k];
            if (u.id == this.activeUser.id) {
                break;
            }
        if(u.email.toLowerCase() == emailL)
            return false;
        }
        return true;
    };

    service.getAllUsers(function () {
        //needed for unique checking
    });
}]);
