angular.module('SimonsSteigerbouwPlanning.services.ApiService', [])

.service('ApiService', function() {
    var URL = 'https://planning.simonssteigerbouw.nl/ws';

    var BASE = URL + "/";

    this.getBaseURL = function() {
        return BASE;
    };

    this.getUserEP = function() {
        return BASE + "user";
    };

    this.getItemsEP = function() {
        return BASE + "item";
    };

    this.getClientsEP = function() {
        return BASE + "client";
    };

    this.getRoleEP = function() {
        return BASE + "/role";
    };

    this.getBodyfeatureEP = function() {
        return BASE + "/bodyfeature";
    };

    this.getPermissionEP = function() {
        return BASE + "/permission";
    };

    this.getLoginEP = function() {
        return BASE + "loginapp";
    };

    this.getRegisterEP = function() {
        return BASE + "/register";
    };

    this.getForgotPasswordEP = function() {
        return BASE + "forgotpassword";
    };
});
